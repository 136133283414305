var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaderShow),expression:"loaderShow"}]},[_c('div',{staticClass:"wait"},[_c('Loader',{attrs:{"loaderType":_vm.loaderType}})],1)]),_c('div',{staticClass:"workspace-page"},[(
                Object.keys(_vm.profileData).length > 0 &&
                    Object.keys(_vm.projectData).length > 0 &&
                    _vm.loaderShow === false
            )?[(
                    _vm.projectData.is_sq_completed == true &&
                        _vm.projectData.is_pq_completed == true
                )?[(_vm.displayDraft)?_c('div',{staticClass:"draft-container"},[(_vm.draftByEHD.payload.draft_data.length > 0)?[_c('div',{staticClass:"heading-div"},[_c('p',{staticClass:"heading"},[_vm._v("Full House Space")]),_c('div',{staticClass:"view-by"},[_vm._v(" View by "),_c('div',{staticClass:"viewby-category"},[_c('div',{staticClass:"category-space",class:!_vm.displayDraft
                                                ? 'active-space'
                                                : 'inactive-space',on:{"click":function($event){return _vm.displayDraftByToggle(false)}}},[_vm._v(" Rooms ")]),_c('div',{staticClass:"category-draft",class:_vm.displayDraft
                                                ? 'active-draft'
                                                : 'inactive-draft',on:{"click":function($event){return _vm.displayDraftByToggle(true)}}},[_vm._v(" Full House ")])])])]),_vm._l((_vm.draftByEHD.payload
                                .draft_data),function(draft,index){return _c('div',{key:index,staticClass:"draft-room"},[_c('div',{staticClass:"room-details"},[_c('p',{staticClass:"room-type"},[_vm._v(" "+_vm._s(draft.display_name)+" ")])]),_c('div',{staticClass:"roomplan-container"},_vm._l((draft.draft_scenes),function(ehd){return _c('div',{key:ehd.id},[_c('WorkspaceComponent',{attrs:{"draftImage":ehd.draft_scene.scene_image,"theme":ehd.draft_scene.theme_name,"ogDetails":ehd.original_scene,"price":ehd.draft_scene.price,"noOfProducts":ehd.draft_scene.no_of_products,"noOfServices":ehd.draft_scene.no_of_services,"footerName":ehd.draft_scene.space_name,"products":ehd.draft_scene.no_of_products,"timeCreated":ehd.draft_scene.updated_at,"sceneid":ehd.draft_scene.scene_id,"ehdid":ehd.draft_scene.draft_ehd_id,"requiredDomain":_vm.requiredDomain},on:{"deleteScene":_vm.openDraftModal,"editScene":_vm.editScene}})],1)}),0)])})]:[_vm._m(0)]],2):_c('div',{staticClass:"draft-container"},[(_vm.draftBySpace.payload.draft_data.length > 0)?[_c('div',{staticClass:"heading-div"},[_c('p',{staticClass:"heading"},[_vm._v("Rooms")]),_c('div',{staticClass:"view-by"},[_vm._v(" View by "),_c('div',{staticClass:"viewby-category"},[_c('div',{staticClass:"category-space",class:!_vm.displayDraft
                                                ? 'active-space'
                                                : 'inactive-space',on:{"click":function($event){return _vm.displayDraftByToggle(false)}}},[_vm._v(" Rooms ")]),_c('div',{staticClass:"category-draft",class:_vm.displayDraft
                                                ? 'active-draft'
                                                : 'inactive-draft',on:{"click":function($event){return _vm.displayDraftByToggle(true)}}},[_vm._v(" Full House ")])])])]),_vm._l((_vm.draftBySpace.payload
                                .draft_data),function(draft,index){return _c('div',{key:index,staticClass:"draft-room"},[_c('div',{staticClass:"room-details"},[_c('p',{staticClass:"room-type"},[_vm._v(" "+_vm._s(draft.space_name)+" ")])]),_c('Carousel',{attrs:{"settings":_vm.settings}},_vm._l((draft.draft_scenes),function(ehd){return _c('div',{key:ehd.id},[_c('WorkspaceComponent',{attrs:{"draftImage":ehd.draft_scene.scene_image,"theme":ehd.draft_scene.theme_name,"ogDetails":ehd.original_scene,"price":ehd.draft_scene.price,"footerName":ehd.draft_scene.display_name,"noOfProducts":ehd.draft_scene.no_of_products,"noOfServices":ehd.draft_scene.no_of_services,"space":ehd.draft_scene.space_name,"eta":ehd.draft_scene.ETA,"products":ehd.draft_scene.no_of_products,"timeCreated":ehd.draft_scene.updated_at,"sceneid":ehd.draft_scene.scene_id,"ehdid":ehd.draft_scene.draft_ehd_id
                                                ? ehd.draft_scene
                                                      .draft_ehd_id
                                                : null,"requiredDomain":_vm.requiredDomain,"draftBySpace":true},on:{"deleteScene":_vm.openDraftModal,"editScene":_vm.editScene}})],1)}),0)],1)})]:[_vm._m(1)]],2)]:[_c('div',{staticClass:"workspace-onboarding"},[_c('div',{staticClass:"onboarding-msg"},[_c('p',{staticClass:"onboarding-head"},[_vm._v("Oh No!")]),_c('img',{staticClass:"onboarding-img",attrs:{"src":require("@/assets/images/undraw_feeling_blue_4b7q.png")}}),_c('p',[_vm._v(" Please complete onboarding to use this section ")]),_c('a',{attrs:{"href":(_vm.url + "/?startQuiz=true&survey_type=PQ")}},[_c('Button',{attrs:{"primary":"","name":"Finish Onboarding"}})],1)])])]]:_vm._e(),(_vm.draftModal)?_c('Modal',{attrs:{"transparent":""},on:{"close":_vm.closeDraftModal}},[_c('div',{staticClass:"draft-modal"},[_c('div',{staticClass:"draft-modal-view"},[_c('p',[_vm._v(" This action is irreversible. Are you sure you want to continue? ")]),_c('div',{staticClass:"result-button",staticStyle:{"margin-top":"20px","display":"flex","justify-content":"center"}},[_c('Button',{staticClass:"delete-draft",attrs:{"name":"yes","outline":""},nativeOn:{"click":function($event){return _vm.deleteScene()}}}),_c('Button',{staticClass:"override",staticStyle:{"margin-left":"20px"},attrs:{"name":"no","primary":""},nativeOn:{"click":function($event){return _vm.closeDraftModal($event)}}})],1)])])]):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-draft"},[_c('img',{attrs:{"src":require("@/assets/images/no-draft.png"),"alt":"No Draft Data"}}),_c('p',[_vm._v("No Draft Data")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-draft"},[_c('img',{attrs:{"src":require("@/assets/images/no-draft.png"),"alt":"No Draft Data"}}),_c('p',[_vm._v("No Draft Data")])])}]

export { render, staticRenderFns }
<template>
    <div>
        <div v-show="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </div>
        <div class="workspace-page">
            <template
                v-if="
                    Object.keys(profileData).length > 0 &&
                        Object.keys(projectData).length > 0 &&
                        loaderShow === false
                "
            >
                <template
                    v-if="
                        projectData.is_sq_completed == true &&
                            projectData.is_pq_completed == true
                    "
                >
                    <!-- <Tabs>
                    <Tab :title="`Project`" :key="`Project`">
                        <div class="project-container">
                            <div class="project-details-action">
                                <div class="project-details">
                                    <div class="project-attr-wrapper">
                                        <p class="project-attr">
                                            House Details
                                        </p>
                                        <p class="project-value">
                                            {{
                                                projectData.user_unit_list[0]
                                                    .house_details
                                            }}
                                        </p>
                                    </div>
                                    <div class="project-attr-wrapper">
                                        <p class="project-attr">Family</p>
                                        <p class="project-value">
                                            {{
                                                projectData.user_unit_list[0]
                                                    .family
                                            }}
                                        </p>
                                    </div>
                                    <div class="project-attr-wrapper">
                                        <p class="project-attr">
                                            House Type
                                        </p>
                                        <p class="project-value">
                                            {{
                                                projectData.user_unit_list[0]
                                                    .house_type
                                            }}
                                        </p>
                                    </div>
                                    <div class="project-attr-wrapper">
                                        <p class="project-attr">Status</p>
                                        <p class="project-value">
                                            <template
                                                v-if="
                                                    projectData
                                                        .user_unit_list[0]
                                                        .verified == true
                                                "
                                            >
                                                Verified
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="19"
                                                    height="19"
                                                >
                                                    <g data-name="Group 854">
                                                        <path
                                                            data-name="Polygon 30"
                                                            d="m9.5 0 2.076 2.555 3.113-1.016.379 3.283 3.163.846-1.439 2.969L19 11.076l-2.8 1.712.552 3.258-3.27-.088L12.2 19l-2.7-1.861L6.8 19l-1.283-3.042-3.27.088.553-3.258L0 11.076l2.208-2.439L.77 5.668l3.163-.846.379-3.283 3.112 1.016z"
                                                            style="fill:#126a18"
                                                        />
                                                        <g
                                                            data-name="icon/action/check_circle_24px"
                                                        >
                                                            <path
                                                                data-name="↳Color"
                                                                d="m2.546 4.922-1.91-1.95-.636.65 2.546 2.6L8 .65 7.364 0z"
                                                                transform="translate(5.5 6.389)"
                                                                style="fill:#fff"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </template>
                                            <template v-else>
                                                Verification in-progress
                                                <span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="15.362"
                                                        height="22"
                                                    >
                                                        <g
                                                            id="noun_Time_1833528"
                                                            transform="translate(-5.084 -1.934)"
                                                        >
                                                            <g
                                                                id="Group_146"
                                                                data-name="Group 146"
                                                                transform="translate(5.084 1.934)"
                                                            >
                                                                <path
                                                                    id="Path_832"
                                                                    data-name="Path 832"
                                                                    fill="#b9a209"
                                                                    d="M14.415 12.033a6.929 6.929 0 0 0 3.929-6.226V3.031h2.1v-1.1H5.084v1.1h2.1V5.84a6.943 6.943 0 0 0 3.892 6.209 1.041 1.041 0 0 1 0 1.867 6.936 6.936 0 0 0-3.891 6.208v2.714h-2.1v1.1h15.361v-1.1h-2.1v-2.693a6.945 6.945 0 0 0-3.957-6.239 1.036 1.036 0 0 1-.585-.93 1.048 1.048 0 0 1 .611-.943zM8.283 5.84V3.121h8.965v2.686a5.73 5.73 0 0 1-.279 1.741c-.017.051-.04.11-.061.166l-.058.154a6.222 6.222 0 0 1-2.2 2.762l-.634-.895a5.175 5.175 0 0 0 1.775-2.187H8.55a5.732 5.732 0 0 1-.267-1.708zm5.666 9.073a5.826 5.826 0 0 1 3.3 5.232v.9c-2.137-.1-3.291-.8-3.885-1.758a.647.647 0 0 0-1.109-.006 4.634 4.634 0 0 1-3.971 1.967v-1.126a5.83 5.83 0 0 1 3.271-5.222 2.139 2.139 0 0 0 1.155-1.535l.033-.024a2.166 2.166 0 0 0 1.206 1.568z"
                                                                    transform="translate(-5.084 -1.934)"
                                                                />
                                                                <circle
                                                                    id="Ellipse_28"
                                                                    data-name="Ellipse 28"
                                                                    class="cls-1"
                                                                    cx=".533"
                                                                    cy=".533"
                                                                    r=".533"
                                                                    transform="translate(7.148 15.248)"
                                                                />
                                                                <circle
                                                                    id="Ellipse_29"
                                                                    data-name="Ellipse 29"
                                                                    class="cls-1"
                                                                    cx=".533"
                                                                    cy=".533"
                                                                    r=".533"
                                                                    transform="translate(7.148 13.32)"
                                                                />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>
                                            </template>
                                        </p>
                                    </div>
                                </div>
                                <router-link
                                    v-if="Object.keys(draft).length > 0"
                                    :to="{
                                        name: 'FinaliseAndPlaceOrderPage',
                                        query: { ehd_id: draft.id },
                                    }"
                                >
                                    <Button primary name="Preview & Finalize" />
                                </router-link>
                                <router-link
                                    v-else
                                    :to="{
                                        name: 'EHDPage',
                                        params: {
                                            id: projectData.calculated_id,
                                        },
                                    }"
                                >
                                    <Button primary name="Customise" />
                                </router-link>
                                <template v-if="isPQ == true">
                                    <router-link
                                        :to="{
                                            name: 'EHDPage',
                                            params: {
                                                id: projectData.calculated_id,
                                            },
                                        }"
                                    >
                                        <Button primary name="Customise" />
                                    </router-link>
                                </template>
                                <a
                                    v-else-if="isPQ == false"
                                    :href="
                                        `${url}?startQuiz=true&survey_type=PQ`
                                    "
                                >
                                    <Button primary name="Start your project" />
                                </a>
                                <a v-else :href="`${url}?startQuiz=true`">
                                    <Button primary name="Take a Style Quiz" />
                                </a>
                            </div>
                            <div class="project-scene"></div>
                        </div>
                    </Tab>
                </Tabs>

                <div
                    class="draft-container"
                    v-if="Object.keys(draft).length > 0"
                >
                    <h1>My Drafts</h1>
                    <div class="carousel-container">
                        <div class="carousel-name"></div>
                        <div class="carousel">
                            <Carousel :settings="settings">
                                <div
                                    class="card"
                                    v-for="(scene, index) in draft.scene_data"
                                    :key="scene + '_' + index"
                                >
                                    <div class="draft-card">
                                        <div class="draft-card-photo">
                                            <img
                                                class="s_photo"
                                                :src="
                                                    scene.scene_image_data_list
                                                        .length > 0
                                                        ? scene
                                                              .scene_image_data_list[0]
                                                              .scene_image_url
                                                              .w_image
                                                        : require('assets/images/bedroom.png')
                                                "
                                                alt=""
                                            />
                                        </div>
                                        <div class="details">
                                            <p class="style">
                                                {{ scene.room_type }}
                                            </p>
                                            <p class="size">
                                                {{
                                                    scene
                                                        .furnishing_products_in_scene
                                                        .length
                                                }}
                                                Products
                                            </p>
                                            <p class="cost">
                                                ₹{{ scene.price }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div> -->

                    <div v-if="displayDraft" class="draft-container">
                        <template
                            v-if="draftByEHD.payload.draft_data.length > 0"
                        >
                            <div class="heading-div">
                                <p class="heading">Full House Space</p>
                                <div class="view-by">
                                    View by
                                    <div class="viewby-category">
                                        <div
                                            class="category-space"
                                            :class="
                                                !displayDraft
                                                    ? 'active-space'
                                                    : 'inactive-space'
                                            "
                                            @click="displayDraftByToggle(false)"
                                        >
                                            Rooms
                                        </div>
                                        <div
                                            class="category-draft"
                                            :class="
                                                displayDraft
                                                    ? 'active-draft'
                                                    : 'inactive-draft'
                                            "
                                            @click="displayDraftByToggle(true)"
                                        >
                                            Full House
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-for="(draft, index) in draftByEHD.payload
                                    .draft_data"
                                :key="index"
                                class="draft-room"
                            >
                                <div class="room-details">
                                    <p class="room-type">
                                        {{ draft.display_name }}
                                    </p>
                                </div>
                                <div class="roomplan-container">
                                    <div
                                        v-for="ehd in draft.draft_scenes"
                                        :key="ehd.id"
                                    >
                                        <WorkspaceComponent
                                            :draftImage="
                                                ehd.draft_scene.scene_image
                                            "
                                            :theme="ehd.draft_scene.theme_name"
                                            :ogDetails="ehd.original_scene"
                                            :price="ehd.draft_scene.price"
                                            :noOfProducts="
                                                ehd.draft_scene.no_of_products
                                            "
                                            :noOfServices="
                                                ehd.draft_scene.no_of_services
                                            "
                                            :footerName="
                                                ehd.draft_scene.space_name
                                            "
                                            :products="
                                                ehd.draft_scene.no_of_products
                                            "
                                            :timeCreated="
                                                ehd.draft_scene.updated_at
                                            "
                                            :sceneid="ehd.draft_scene.scene_id"
                                            :ehdid="
                                                ehd.draft_scene.draft_ehd_id
                                            "
                                            :requiredDomain="requiredDomain"
                                            @deleteScene="openDraftModal"
                                            @editScene="editScene"
                                        />
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="no-draft">
                                <img
                                    src="@/assets/images/no-draft.png"
                                    alt="No Draft Data"
                                />
                                <p>No Draft Data</p>
                            </div>
                        </template>
                    </div>
                    <div v-else class="draft-container">
                        <template
                            v-if="draftBySpace.payload.draft_data.length > 0"
                        >
                            <div class="heading-div">
                                <p class="heading">Rooms</p>
                                <div class="view-by">
                                    View by
                                    <div class="viewby-category">
                                        <div
                                            class="category-space"
                                            :class="
                                                !displayDraft
                                                    ? 'active-space'
                                                    : 'inactive-space'
                                            "
                                            @click="displayDraftByToggle(false)"
                                        >
                                            Rooms
                                        </div>
                                        <div
                                            class="category-draft"
                                            :class="
                                                displayDraft
                                                    ? 'active-draft'
                                                    : 'inactive-draft'
                                            "
                                            @click="displayDraftByToggle(true)"
                                        >
                                            Full House
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-for="(draft, index) in draftBySpace.payload
                                    .draft_data"
                                :key="index"
                                class="draft-room"
                            >
                                <div class="room-details">
                                    <p class="room-type">
                                        {{ draft.space_name }}
                                    </p>
                                </div>
                                <Carousel :settings="settings">
                                    <div
                                        v-for="ehd in draft.draft_scenes"
                                        :key="ehd.id"
                                    >
                                        <WorkspaceComponent
                                            :draftImage="
                                                ehd.draft_scene.scene_image
                                            "
                                            :theme="ehd.draft_scene.theme_name"
                                            :ogDetails="ehd.original_scene"
                                            :price="ehd.draft_scene.price"
                                            :footerName="
                                                ehd.draft_scene.display_name
                                            "
                                            :noOfProducts="
                                                ehd.draft_scene.no_of_products
                                            "
                                            :noOfServices="
                                                ehd.draft_scene.no_of_services
                                            "
                                            :space="ehd.draft_scene.space_name"
                                            :eta="ehd.draft_scene.ETA"
                                            :products="
                                                ehd.draft_scene.no_of_products
                                            "
                                            :timeCreated="
                                                ehd.draft_scene.updated_at
                                            "
                                            :sceneid="ehd.draft_scene.scene_id"
                                            :ehdid="
                                                ehd.draft_scene.draft_ehd_id
                                                    ? ehd.draft_scene
                                                          .draft_ehd_id
                                                    : null
                                            "
                                            :requiredDomain="requiredDomain"
                                            @deleteScene="openDraftModal"
                                            @editScene="editScene"
                                            :draftBySpace="true"
                                        />
                                    </div>
                                </Carousel>
                            </div>
                        </template>
                        <template v-else>
                            <div class="no-draft">
                                <img
                                    src="@/assets/images/no-draft.png"
                                    alt="No Draft Data"
                                />
                                <p>No Draft Data</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="workspace-onboarding">
                        <!-- <div class="project-details">
                        <div class="project-attr-wrapper">
                            <p class="project-attr">House Details</p>
                            <p class="project-value">
                                -
                            </p>
                        </div>
                        <div class="project-attr-wrapper">
                            <p class="project-attr">Family</p>
                            <p class="project-value">
                                -
                            </p>
                        </div>
                        <div class="project-attr-wrapper">
                            <p class="project-attr">House type</p>
                            <p class="project-value">
                                -
                            </p>
                        </div>
                        <div class="project-attr-wrapper">
                            <p class="project-attr">Status</p>
                            <p class="project-value">
                                -
                            </p>
                        </div>
                    </div> -->
                        <div class="onboarding-msg">
                            <p class="onboarding-head">Oh No!</p>
                            <img
                                class="onboarding-img"
                                src="@/assets/images/undraw_feeling_blue_4b7q.png"
                            />
                            <p>
                                Please complete onboarding to use this section
                            </p>
                            <a :href="`${url}/?startQuiz=true&survey_type=PQ`">
                                <Button primary name="Finish Onboarding" />
                            </a>
                        </div>
                        <!-- <img class="house-img" src="@/assets/images/house.png" /> -->
                    </div>
                </template>
            </template>
            <Modal v-if="draftModal" @close="closeDraftModal" transparent>
                <div class="draft-modal">
                    <div class="draft-modal-view">
                        <p>
                            This action is irreversible. Are you sure you want
                            to continue?
                        </p>
                        <div
                            class="result-button"
                            style="margin-top: 20px; display: flex; justify-content: center;"
                        >
                            <Button
                                class="delete-draft"
                                name="yes"
                                outline
                                @click.native="deleteScene()"
                            />
                            <Button
                                style="margin-left:20px;"
                                class="override"
                                name="no"
                                primary
                                @click.native="closeDraftModal"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import WorkspaceModule from 'store/modules/Workspace'
// import Tabs from 'componentsv2/Tabs'
// import Tab from 'componentsv2/Tab'
import Button from 'componentsv2/Button'
import Carousel from 'componentsv2/Carousel'
import WorkspaceComponent from 'componentsv2/WorkspaceComponent'
import Loader from 'componentsv2/Loader'
import Modal from 'componentsv2/Modal'
import { deleteSceneFromDraft } from 'api/workspaceApi'
import LoaderHandler from 'mixins/loader'

export default {
    name: 'MyWorkspace',
    props: [
        'profileData',
        'projectData',
        'draft',
        'url',
        'isPQ',
        'isSQ',
        'designIdeas',
        'requiredDomain',
    ],
    components: {
        //Tabs,
        //Tab,
        Button,
        Carousel,
        WorkspaceComponent,
        Loader,
        Modal,
    },
    mixins: [RegisterStoreModule, LoaderHandler],
    data() {
        return {
            settings: {
                arrows: true,
                focusOnSelect: false,
                speed: 500,
                slidesToShow: 2,
                infinite: false,
                slidesToScroll: 1,
                touchThreshold: 5,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 850,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
            displayDraft: false,
            draftModal: false,
            sceneid: null,
        }
    },
    computed: {
        ...mapState({
            draftByEHD: state => state.WorkspaceModule.draftByEHD,
            draftBySpace: state => state.WorkspaceModule.draftBySpace,
        }),
    },
    created() {
        this.registerStoreModule('WorkspaceModule', WorkspaceModule)
        //document.querySelector('body').style.overflow = 'hidden'
        this.fetchDraftBySpace()
            .then(payload => {
                if (payload.responseCode == 200) {
                    setTimeout(() => {
                        this.loaderShow = false
                        document.querySelector('body').style.overflow = 'auto'
                    }, 100)
                } else {
                    setTimeout(() => {
                        this.loaderShow = false
                        document.querySelector('body').style.overflow = 'auto'
                    }, 100)
                }
            })
            .catch(err => {
                setTimeout(() => {
                    this.loaderShow = false
                    console.log(this.loaderShow)
                }, 100)
            })
    },
    methods: {
        ...mapActions({
            fetchDraftByEHD: 'fetchDraftByEHD',
            fetchDraftBySpace: 'fetchDraftBySpace',
            updateDraftBySpace: 'updateDraftBySpace',
            updateDraftByEHD: 'updateDraftByEHD',
        }),
        displayDraftByToggle(setFlag) {
            if (this.displayDraft != setFlag) {
                this.displayDraft = setFlag
                this.loaderShow = true
                if (this.displayDraft == true) {
                    if (this.draftByEHD.payload == null) {
                        this.fetchDraftByEHD()
                            .then(payload => {
                                if (payload) {
                                    if (payload.responseCode == 200) {
                                        setTimeout(() => {
                                            this.loaderShow = false
                                            document.querySelector(
                                                'body',
                                            ).style.overflow = 'auto'
                                        }, 600)
                                    }
                                } else {
                                    setTimeout(() => {
                                        this.loaderShow = false
                                        document.querySelector(
                                            'body',
                                        ).style.overflow = 'auto'
                                    }, 600)
                                }
                            })
                            .catch(err => {
                                setTimeout(() => {
                                    this.loaderShow = false
                                }, 600)
                            })
                    } else {
                        setTimeout(() => {
                            this.loaderShow = false
                        }, 600)
                    }
                } else {
                    setTimeout(() => {
                        this.loaderShow = false
                    }, 600)
                }
            }
        },
        closeDraftModal() {
            this.draftModal = false
            document.querySelector('body').style.overflow = 'auto'
        },
        openDraftModal(obj) {
            this.draftModal = true
            document.querySelector('body').style.overflow = 'hidden'
            this.sceneid = obj.scene_id
        },
        deleteScene() {
            this.loaderShow = true
            this.closeDraftModal()
            // document.querySelector('.draft-container').style.display = 'none'
            window.scrollTo(0, 0)
            document.querySelector('body').style.overflow = 'hidden'
            deleteSceneFromDraft(this.sceneid)
                .then(response => {
                    if (response.responseCode == 200) {
                        let spaceDrafts = this.draftBySpace
                        spaceDrafts.payload.draft_data.forEach(
                            (draft, index) => {
                                draft.draft_scenes.forEach((scene, idx) => {
                                    if (
                                        scene.draft_scene.scene_id ==
                                        this.sceneid
                                    ) {
                                        if (draft.draft_scenes.length > 1) {
                                            spaceDrafts.payload.draft_data[
                                                index
                                            ].draft_scenes.splice(idx, 1)
                                        } else {
                                            spaceDrafts.payload.draft_data.splice(
                                                index,
                                                1,
                                            )
                                        }
                                    }
                                })
                            },
                        )
                        this.updateDraftBySpace(spaceDrafts)

                        if (Object.keys(this.draftByEHD).length > 0) {
                            let ehdDrafts = this.draftByEHD
                            ehdDrafts.payload.draft_data.forEach(
                                (draft, index) => {
                                    draft.draft_scenes.forEach((scene, idx) => {
                                        if (
                                            scene.draft_scene.scene_id ==
                                            this.sceneid
                                        ) {
                                            if (draft.draft_scenes.length > 1) {
                                                ehdDrafts.payload.draft_data[
                                                    index
                                                ].draft_scenes.splice(idx, 1)
                                            } else {
                                                ehdDrafts.payload.draft_data.splice(
                                                    index,
                                                    1,
                                                )
                                            }
                                        }
                                    })
                                },
                            )
                            this.updateDraftByEHD(ehdDrafts)
                        }
                    }
                    setTimeout(() => {
                        this.loaderShow = false
                        document.querySelector('body').style.overflow = 'auto'
                        // document.querySelector('.draft-container').style.display = 'block'
                    }, 100)
                })
                .catch(err => {
                    setTimeout(() => {
                        this.loaderShow = false
                        document.querySelector('body').style.overflow = 'auto'
                    }, 100)
                })
        },
        editScene(obj) {
            this.$router.push({
                path: `/ehd/${obj.ehd_id}`,
                query: { scene: obj.scene_id },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import './MyWorkspace.scss';
</style>

import Api from 'utils/Api'

export function getDraftByEHD() {
    return Api
        .get('usermanagement/get_draft_group_by_ehd')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getDraftBySpace() {
    return Api
        .get('usermanagement/get_draft_by_space_type')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
export function deleteSceneFromDraft(scene_id) {
    return Api
        .post(`CADVisualization/ehdPage/delete_draft_scene/`, { scene_id: scene_id })
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
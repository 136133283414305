import { getDraftByEHD, getDraftBySpace } from 'api/workspaceApi'

const Workspace = {
    state: () => ({
        draftByEHD: {},
        draftBySpace: {},
    }),

    mutations: {
        setDraftByEHD(state, payload) {
            state.draftByEHD = payload
        },
        setDraftBySpace(state, payload) {
            state.draftBySpace = payload
        },
    },

    getters: {},

    actions: {
        fetchDraftByEHD({ commit }) {
            return getDraftByEHD()
                .then(response => {
                    if (response.responseCode != 200) {
                        console.log('fetching failed')
                    } else {
                        commit('setDraftByEHD', response)
                        return response
                    }
                })
        },
        fetchDraftBySpace({ commit }) {
            return getDraftBySpace()
                .then(response => {
                    if (response.responseCode != 200) {
                        console.log('fetching failed')
                    } else {
                        commit('setDraftBySpace', response)
                        return response
                    }
                })
        },
        updateDraftBySpace({ commit }, obj) {
            commit('setDraftBySpace', obj)
        },
        updateDraftByEHD({ commit }, obj) {
            commit('setDraftByEHD', obj)
        },


    }
}

export default Workspace;